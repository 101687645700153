.SimpleButton{
    padding: 0.7rem 1.5rem;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.5s ease-in;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.SimpleButton p{
    padding: 0;
    margin: 0;
}

.SimpleButton:hover{
    background-color: var(--color-primary);
    color: var(--color-background);
}