.About{
    width: 85%;
    box-sizing: border-box;
    padding-left: 10vw;
    padding-top: 8rem;
    padding-bottom:3rem;
}
.AboutTop{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.AboutText{
    padding-right: 6rem;
    text-align: start;
    color: var(--color-text);
}



@media screen and (max-width: 1000px) {
    .About{
        width: 85%;
    }
    .AboutTop{
        flex-direction: column;
    }
    
    .AboutText{
        padding-right: 0;
        padding-bottom: 3rem;
    }
}