.TextArea{
    border:2px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 0.5rem;
    margin:0;
    padding: 0.5rem 1rem;
    background:var(--color-background-primary);
    font-family: 'Space Mono', monospace;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing:border-box;
    resize:none;
    font-size: 1rem;
}