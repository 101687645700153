.HomeProjectCard{
    width: 100%;
    border-radius: 1rem;
    background: var(--color-background-secondary);
    overflow: hidden;
    display: none;;
}
.HomeProjectCardTitle{
    font-size: 2rem;
    margin-top: 0;
}

.ProjectCardImage{
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.ProjectTextContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 2rem;
}


@media screen and (max-width: 1200px) {
    .HomeProjectCard{
        display: block;
    }
}