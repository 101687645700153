:root{
  --color-background-primary:#0A192F;
  --color-background-secondary:#112240;
  --color-primary:#64FFDA;
  --color-text:#FFFFFF;
}
#root {
  width: 100%;
}

@media (min-width: 1440px) {
  #root {
    width: 1440px;
    margin: 0 auto;
  }
}
html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Space Mono', monospace; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background-primary);
  width: 100vw;
  overflow-x: hidden;
}

a{
  text-decoration: none;
  color:inherit;
}

p{
  font-size: 1rem;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: var(--color-background-primary);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: var(--color-primary);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-text);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/** GENERAL **/ 
h2{
  color: var(--color-primary);
  font-size: 4rem;
  font-weight: 300;
  margin: 1rem 0;
}

@media screen and (max-width: 700px) {
  h2{
    font-size: 3rem;
  }
}