.Contact{
    width: 100%;
    box-sizing: border-box;
    padding-left: 10vw;
    padding-top: 8rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
}

.SocialMedia_Bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 3rem;
    gap: 3rem;
}

.SocialMedia_Icon{
    height:3rem;
    width: 3rem;
}
.ContactForm{
    width: 50vw;
    padding-left:4rem;
}
.ContactForm h2{
    margin: 0;
}
.ContactForm .Text{
    color:var(--color-text);
    margin-bottom:2rem;
}

.TopContact{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:2rem;
}

.SubmitButton{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}


@media screen and (max-width: 700px) {
    .Contact{
        flex-direction: column-reverse;
        padding-right: 10vw;
    }
    .ContactForm{
        width: 100%;
        padding-left: 0;

    }


    .SocialMedia_Bar{
        flex-direction: row;
        gap: 1rem;
        padding-top: 1rem;
    }
}