.Header{
    position: fixed;
    box-sizing: border-box;
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    z-index:100;
    background: var(--color-background-primary);
}
.Logo{
    color: var(--color-text);
}

.Header {
  width: 100%;
}

@media (min-width: 1440px) {
  .Header {
    width: 1440px;
    margin: 0 auto;
  }
}