.Project{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding-right: 4rem;
    margin-bottom: 10rem;
    z-index: 1;
    cursor: pointer;
}
.Project_Reversed{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.ProjectImageContainer{
    position: relative;
    width:60%;
    z-index: 1;
    border-radius: 1rem;
    overflow: hidden;
}

.ProjectImage{
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
    border-radius: 1rem;
}
.ProjectImageOverlay{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 25, 47,0.6);
    z-index: 2;
    transition:0.5s;
}

.ProjectImageContainer:hover .ProjectImageOverlay{
    background-color: rgba(100, 255, 219,0.8);
}



.ProjectTitle{
    position: relative;
    top: 2rem;
    color: var(--color-primary);
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 2rem;
    font-weight: 400;
}

.ProjectTextCard{
    bottom: -20%;
    right: 15%;
    width: 40%;
    background: var(--color-background-secondary);
    position: absolute;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    z-index: 1;
}
.ProjectTextCard_Reversed{
    left: 0%;
}

.ProjectText{
    margin: 0;
    font-size: 0.8rem;
    color: var(--color-text);
}

.ProjectAtributes{
    padding-top: 1rem;
    display:flex;
    gap: 1rem;
    justify-content: space-around; 
}
.Attribute{
    color: var(--color-primary);
    margin:0;
}



@media screen and (max-width: 1200px) {
    .Project{
        display: none;
        flex-direction: column;
    }
    .ProjectImageContainer{
        z-index: 2;
        width: 90%;
    }
    
    .ProjectTextCard{
        width: 90%;        
        bottom: 0;
        right: 0;
    }
    
    .ProjectText{
        font-size: 0.8rem;
    }
    .Attribute{
        font-size: 0.8rem;
    }
}