.Projects{
    width: 100%;
    box-sizing: border-box;
    padding-left: 10vw;
    padding-top: 8rem;
}

.ProjectCardsContainer{
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
    padding-bottom:2rem;
}


@media screen and (max-width: 1100px) {
    .ProjectCardsContainer{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 700px) {
    .ProjectCardsContainer{
        grid-template-columns: 1fr;
    }
}